<template>
    <b-modal id="filename-modal" v-model="$modals.filename" hide-footer title="Name your worksheet">
        <form id="filename-form" @submit.prevent="setFilename">
            <b-form-group
                id="filename-input-group"
                label="Save Worksheet As:"
                label-for="filename-input"
                :invalid-feedback="errors.get('filename')"
                :state="errors.has('filename') ? false : null"
            >
                <b-form-input
                    id="filename-input"
                    v-model="filename"
                    :state="errors.has('filename') ? false : null"
                    trim
                ></b-form-input>
            </b-form-group>

            <div>
                <b-button variant="primary" type="submit">Save Worksheet</b-button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import { defineComponent } from 'vue'
import Errors from '../libraries/errors'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'FilenameModal',
    data() {
        return {
            filename: '',
            errors: new Errors(),
        }
    },
    computed: {
        ...mapGetters({
            persistAction: 'document/persistAction',
        }),
    },
    methods: {
        setFilename() {
            this.errors.clear()

            if (this.filename === '') {
                this.errors.set('filename', 'Please enter a name for your document.')
            }

            if (!this.errors.any()) {
                this.$store.dispatch('document/setDocument', {
                    filename: this.filename,
                })
                this.$store.dispatch(this.persistAction)
                this.$modals.close('filename')
            }
        },
    },
})
</script>
