<template>
    <div class="print-cover">
        <template v-if="isLoggedIn">
            <div class="text-center">
                <h2 style="font-weight: normal">Thanks for trying us out!</h2>
                We are thrilled you have joined our community of educators.
                <hr />
                <h1>Your Worksheet Must be ‘Published’ to Print</h1>
            </div>

            <p>
                <strong>It takes just a few seconds to publish your worksheet:</strong>
            </p>

            <ol>
                <li>
                    Click
                    <strong>“Print”</strong>
                    on the left sidebar.
                </li>
                <li>Fill out the grade level and subject information.</li>
                <li>
                    Click
                    <strong>“Make Printable!”</strong>
                    and
                    <em>--voila!--</em>
                    you are ready to print.
                </li>
            </ol>
        </template>
        <template v-else>
            <div class="text-center">
                <h2 style="font-weight: normal">Thanks for trying us out!</h2>
                We are so excited for you to join our community of educators.
                <hr />
                <h1>Please Log In to Print Your Worksheet</h1>
            </div>

            <p>
                <strong>It takes just a few seconds to create your free account:</strong>
            </p>

            <ol>
                <li>
                    Click on
                    <strong>‘Sign Up’</strong>
                    on the left sidebar. If you already have an account, click
                    <strong>‘Log In’</strong>
                    .
                </li>
                <li>
                    Provide your email address, create a password and
                    <em>--voila!--</em>
                    you’re in.
                </li>
                <li>
                    Click
                    <strong>‘Print’</strong>
                    in the left sidebar to publish your worksheet and make it printable.
                </li>
            </ol>
        </template>

        <div class="print-foot">
            <p>
                <em>Thank you for using My Worksheet Maker and for supporting educators around the world!</em>
            </p>

            <img src="img/logo-icon-purple.svg" style="height: 48px" />
        </div>
    </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
    name: 'PrintCovers',
    computed: {
        ...mapGetters({
            isLoggedIn: 'user/isLoggedIn',
        }),
    },
})
</script>

<style lang="scss">
@import 'Scss/base.scss';

.print-cover {
    color: $black;
    display: none !important;
    position: fixed;
    background-color: #fff;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 10000;

    hr {
        margin: 20px;
        background-color: #000;
        height: 1px;
        border: 0px;
    }

    .print-foot {
        margin-top: 35px;
        text-align: center;
    }
    @media print {
        display: block;
    }
}
</style>
