<template>
    <footer class="master-footer pb-4">
        &copy; 2013 - {{ year }} Crossword Hobbyist Inc. All rights reserved. By using this site you agree to be bound by our
        <a href="/terms" class="navbar-item">Terms &amp; Conditions</a>
        ,
        <a href="/privacy" class="navbar-item">Privacy Policy</a>
        , and
        <a href="/payments-and-refunds" class="navbar-item">Payments &amp; Refunds</a>
        Policies.
    </footer>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

interface Data {
    year: number
}
export default defineComponent({
    name: 'CopyrightDisclaimers',
    data(): Data {
        return {
            year: new Date().getFullYear(),
        }
    },
})
</script>

<style scoped></style>
