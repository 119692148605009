<template>
    <div class="mb-1">
        <div v-if="collapsible" class="border-action w-100 d-flex justify-content-center align-items-center">
            <div class="line"></div>
            <button
                :class="isOpen ? 'btn-primary' : 'btn-outline-primary'"
                class="btn rounded btn-sm text-uppercase"
                @click="toggleSettings"
            >
                <span>Border</span>
                <IconPalette class="icon-palette" />
                <b-icon icon="chevron-down" />
            </button>
            <div class="line"></div>
        </div>
        <div v-show="isOpen" class="border-settings">
            <div class="row">
                <div class="col-md-9">
                    <label class="font-weight-bold mb-1 f-11 text-muted">Border</label>
                    <div class="d-flex f-10 justify-content-between mb-0">
                        <div>Thin</div>
                        <div>Thick</div>
                    </div>
                    <b-form-input
                        v-model="border.border_width"
                        type="range"
                        min="0"
                        max="8"
                        @change="handleInput"
                    ></b-form-input>
                </div>

                <div class="col-md-3">
                    <ColorPicker v-model="border.border_color" label="Color" @change="handleInput" />
                </div>
            </div>

            <div>
                <label class="font-weight-bold mb-0 f-11 text-muted mt-2">Border Style</label>
                <b-form-radio-group id="border-style" v-model="border.border_style" name="border-style">
                    <div class="d-flex row">
                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="groove" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label groove w-100"></div>
                                <span class="f-11 text-muted text-height-1">Groove</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="dashed" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label dashed w-100"></div>
                                <span class="f-11 text-muted text-height-1">Dashed</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="solid" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label solid w-100"></div>
                                <span class="f-11 text-muted text-height-1">Solid</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="outset" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label outset w-100"></div>
                                <span class="f-11 text-muted text-height-1">Outset</span>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex mt-2 row">
                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="dotted" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label dotted w-100"></div>
                                <span class="f-11 text-muted text-height-1">Dotted</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="double" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label double w-100"></div>
                                <span class="f-11 text-muted text-height-1">Double</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="ridge" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label ridge w-100"></div>
                                <span class="f-11 text-muted text-height-1">Ridge</span>
                            </div>
                        </div>

                        <div class="d-flex align-items-center col-md-3">
                            <b-form-radio value="inset" @change="handleInput"></b-form-radio>
                            <div class="d-flex flex-column">
                                <div class="border-line-label inset w-100"></div>
                                <span class="f-11 text-muted text-height-1">Inset</span>
                            </div>
                        </div>
                    </div>
                </b-form-radio-group>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
import { BorderSettingsOptions } from '../objects/BorderSettingsOption'
import ColorPicker from '../components/ColorPicker.vue'
import IconPalette from '../stories/components/icons/IconPalette.vue'

export default defineComponent({
    name: 'BorderSettings',
    components: {
        ColorPicker,
        IconPalette,
    },
    props: {
        value: {
            type: Object as PropType<BorderSettingsOptions>,
            default: undefined,
        },
        collapsible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            border: this.value,
            isOpen: !this.collapsible,
        }
    },
    methods: {
        handleInput() {
            this.$emit('input', this.border)
        },
        toggleSettings() {
            this.isOpen = !this.isOpen
        },
    },
})
</script>

<style lang="scss" scoped>
.border-settings {
    .custom-control-inline {
        margin-right: 0;
    }
}
.line {
    flex-grow: 1;
    border-top: 1px dashed #adb5bd;
}

.icon-palette {
    margin-right: 0.75rem;
    margin-left: 0.3125rem;
}
</style>
